import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { SelectableBox } from "@src/appV2/redesign/components/SelectableBox";
import { format } from "date-fns";

import { type PlacementDetail } from "../api/useGetPlacement";

export interface InterviewTime {
  start: Date;
  end: Date;
}

interface InterviewTimeSelectorProps {
  placementDetails: PlacementDetail;
  timeSlots: InterviewTime[];
  selectedTime: InterviewTime | undefined;
  onTimeSelect: (time: InterviewTime) => void;
  isReschedule?: boolean;
}

export function InterviewTimeSelector(props: InterviewTimeSelectorProps) {
  const { placementDetails, timeSlots, selectedTime, onTimeSelect, isReschedule = false } = props;

  const isProspectWorkplace = Boolean(placementDetails.workplace.placementSettings?.isProspect);

  const eventContext = {
    placementId: placementDetails.id,
    placementApplicationId: placementDetails.applications?.[0]?.id,
    isReschedule,
    isProspectWorkplace,
  };

  const handleTimeSelect = (time: InterviewTime) => {
    onTimeSelect(time);

    // Track time selection
    logEvent(APP_V2_APP_EVENTS.INTERVIEW_TIME_SELECTED, {
      ...eventContext,
      selectedDate: format(time.start, "yyyy-MM-dd"),
      selectedTimeRange: `${format(time.start, "h:mm aaa")} - ${format(time.end, "h:mm aaa")}`,
    });
  };

  return (
    <Stack spacing={2}>
      <Text variant="subtitle1">Available time slots</Text>
      <Stack
        spacing={3}
        sx={{
          height: 300,
          overflowY: "auto",
          p: 1,
        }}
      >
        {timeSlots.map((time) => (
          <SelectableBox
            key={time.start.toISOString()}
            variant="secondary"
            isSelected={selectedTime?.start.getTime() === time.start.getTime()}
            onToggleSelect={() => {
              handleTimeSelect(time);
            }}
          >
            {format(time.start, "h:mm aaa")} - {format(time.end, "h:mm aaa")}
          </SelectableBox>
        ))}
      </Stack>
    </Stack>
  );
}
